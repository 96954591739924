import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | AIZeal',
  defaultTitle: 'AIZeal',
  description: 'Innovation drives, AIZ advances',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@AIZeal_Group',
    site: '@AIZeal_Group',
  },
  openGraph: {
    title: '🥞 AIZeal - Innovation drives, AIZ advances',
    description: 'Innovation drives, AIZ advances',
    images: [{ url: 'https://aizeal.org/images/aizeal.png' }],
  },
}
